<template>
  <lock-scroll :locked="lockScroll">
    <lock-screen :locked="lockScreen" />
    <app-layout>
      <div
        class="flex flex-col items-center py-4"
        :class="{ 'cursor-not-allowed': disabled }"
      >
        <div class="w-full lg:w-4/5 px-4">
          <div class="text-center">
            <span class="text-xl font-bold text-gray-500">
              {{ today }}
            </span>
          </div>
          <div class="text-center py-2">
            <span class="text-xl font-bold text-gray-500">
              Saludos, {{ fullname }}
            </span>
          </div>
          <div class="flex flex-col lg:flex-row py-2 lg:space-x-2">
            <card-button
              :disabled="disabled"
              class="w-full align-middle text-center lg:w-1/3 mb-2"
              name="orders.index"
              :params="{ status: `${restrictedStatuses.PendingAssignment.id}` }"
            >
              <i class="text-3xl bx bx-group align-bottom text-purple-500"></i>
              <span class="text-xl font-bold ml-2">{{
                counts.pendingAssignment
              }}</span>
              <span class="text-xl font-bold ml-2">Por Asignar</span>
            </card-button>
            <card-button
              :disabled="disabled"
              class="w-full align-middle justify-center text-center lg:w-1/3 mb-2"
              name="orders.index"
              :params="{ status: `${restrictedStatuses.InProgress.id}` }"
            >
              <i class="text-3xl bx bx-timer align-bottom text-purple-500"></i>
              <span class="text-xl font-bold ml-2">{{
                counts.inProgress
              }}</span>
              <span class="text-xl font-bold ml-2">En Proceso</span>
            </card-button>
            <card-button
              :disabled="disabled"
              class="w-full align-middle justify-center text-center lg:w-1/3 mb-2"
              name="orders.index"
              :params="{ status: `${restrictedStatuses.ToBeDelivered.id}` }"
            >
              <i
                class="text-3xl bx bx-package align-bottom text-purple-500"
              ></i>
              <span class="text-xl font-bold ml-2">{{
                counts.toBeDelivered
              }}</span>
              <span class="text-xl font-bold ml-2">Por Entregar</span>
            </card-button>
          </div>
          <div class="w-full flex flex-col lg:flex-row space-y-6">
            <div class="w-full lg:w-1/2">
              <chart :options="chartOptions" />
            </div>
            <div
              class="w-full lg:w-1/2"
              style="align-self: center"
              v-if="$store.state.account.accountType === AccountTypeEnum.Owner ||
                  $store.state.account.canCloseBusiness"
            >
              <div class="w-full flex flex-row">
                <div
                  class="w-full flex flex-col"
                  style="justify-content: space-around"
                >
                  <div class="text-center text-2xl mb-2">
                    <h1><strong>Finalizados</strong></h1>
                    <h2>{{ counts.finalized }}</h2>
                  </div>
                  <div class="text-center text-2xl mb-2">
                    <h1><strong>Venta Neta</strong></h1>
                    <h2>{{ moneyFormat(partialSale) }}</h2>
                  </div>
                  <div class="text-center text-2xl mb-2">
                    <h1><strong>Venta Total</strong></h1>
                    <h2>{{ moneyFormat(totalSale) }}</h2>
                  </div>
                </div>
                <div
                  class="w-full flex flex-col"
                  style="justify-content: space-around"
                  v-if="isProMembership"
                >
                  <div class="text-center text-2xl mb-2">
                    <h1><strong>Gastos General</strong></h1>
                    <h2>{{ moneyFormat(totalExpense) }}</h2>
                  </div>
                  <div class="text-center text-2xl mb-2">
                    <h1><strong>Utilidad</strong></h1>
                    <h2>{{ moneyFormat(utility) }}</h2>
                  </div>
                </div>
              </div>
              <div class="w-full flex flex-row justify-center">
                <div
                  class="border border-purple-500 text-xl text-center cursor-pointer flex justify-center items-center"
                  :class="{
                    'bg-purple-500 text-white': active === 1,
                    'cursor-not-allowed': disabled,
                  }"
                  style="min-width: 100px; width: 100px;height: 40px"
                  @click="changeActive(1)"
                >
                  Hoy
                </div>
                <div
                  class="border border-purple-500 text-xl text-center cursor-pointer flex justify-center items-center"
                  :class="{
                    'bg-purple-500 text-white': active === 2,
                    'cursor-not-allowed': disabled,
                  }"
                  style="min-width: 100px; width: 100px;height: 40px"
                  @click="changeActive(2)"
                >
                  Semana
                </div>
                <div
                  class="border border-purple-500 text-xl text-center cursor-pointer flex justify-center items-center"
                  :class="{
                    'bg-purple-500 text-white': active === 3,
                    'cursor-not-allowed': disabled,
                  }"
                  style="min-width: 100px; width: 100px;height: 40px"
                  @click="changeActive(3)"
                >
                  Mes
                </div>
              </div>
              <div
                class="w-full flex flex-row justify-center mt-4"
                v-if="
                  $store.state.business.id &&
                  $store.state.account.canCloseBusiness
                "
              >
                <el-dropdown trigger="click" @command="close">
                  <button
                    class="px-4 py-2 rounded shadow border hover:text-white border-purple-500 hover:border-purple-600 hover:bg-purple-600"
                  >
                    Cerrar
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item

                      :command="CloseBusinessType.Day"
                      :disabled="IsBusinessClosedThisDay"
                      >Día</el-dropdown-item
                    >
                    <el-dropdown-item
                    class="flex items-center"
                      :command="CloseBusinessType.Week"
                      :disabled="IsBusinessClosedThisWeek || !isProMembership"
                      >Semana <pro-link v-if="!isProMembership"></pro-link></el-dropdown-item
                    >
                    <el-dropdown-item
                    class="flex items-center"
                      :command="CloseBusinessType.Month"
                      :disabled="IsBusinessClosedThisMonth || !isProMembership"
                      >Mes <pro-link v-if="!isProMembership"></pro-link></el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </div>
          <div class="flex flex-col space-y-6">
            <content-card
              title="Órdenes con fecha límite en esta semana"
              with-options
              v-loading.fullscreen.lock="loading"
            >
              <div class="py-2">
                <div>
                  <orders-grid
                    :hasActions="!disabled"
                    :orders="orders"
                    @onStatusUpdate="updateStatus"
                    @onSorting="handleSorting"
                  />
                </div>
              </div>
            </content-card>
          </div>
        </div>
      </div>
    </app-layout>
    <el-dialog :visible.sync="showQuickStartDialog" width="75%" @close="start">
      <div class="text-center">
        <div>
          <span class="text-5xl text-gray-500 font-bold"
            >Bienvenido a Samii</span
          >
        </div>
        <div>
          <img
            src="@/assets/img/welcome-samii.png"
            width="320px"
            class="m-auto"
          />
        </div>
        <div>
          <span class="text-4xl text-gray-400">
            El primer software para técnicos hecho por un técnico.
          </span>
        </div>
        <div class="w-full">
          <div class="text-right">
            <el-button class="el-button--primary" @click="start"
              >Crear mi Primer Orden</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
  </lock-scroll>
</template>
<script>
import CardButton from "@/components/molecules/CardButton";
import { DateFormats, CloseBusinessType, AccountTypeEnum } from "@/constants";
import HomeService from "@/services/HomeService";
import OrderService from "@/services/OrderService";
import BusinessService from "@/services/BusinessService";
import OrdersGrid from "@/views/app/orders/grid/Index.vue";
import { Chart } from "highcharts-vue";
import numbers from "@/utils/numbers";
import error from "@/mixins/error";
import { QUICK_START_STORE } from "@/store/modules/quick-start/mutation-types";
import OrderPaymentDialogService, {
  command as OrderPaymentDialogCommand,
  commands as OrderPaymentDialogCommands,
} from "@/components/dialogs/OrderPayment/service";
import { LOCAL_STORAGE_SAMII_KEYS, getItem, setItem } from "@/utils/storage";
import { getRestrictedStatuses } from "@/helpers/order-status.helper";
import TheHeaderAppServices from "@/components/organisms/services/TheHeaderAppServices";

export default {
  name: "HomeIndex",
  mixins: [error],
  components: {
    OrdersGrid,
    ProLink: () => import("@/components/atoms/SaProLink.vue"),
    AppLayout: () => import("@/components/layouts/AppLayout"),
    ContentCard: () => import("@/components/molecules/ContentCard"),
    CardButton,
    Chart,
  },
  data() {
    return {
      isProMembership: this.$store.state.account.membershipType === 2 || this.$store.state.account.membershipType === 3,
      AccountTypeEnum,
      CloseBusinessType,
      loading: false,
      IsBusinessClosedThisDay: false,
      IsBusinessClosedThisWeek: false,
      IsBusinessClosedThisMonth: false,
      active: 1,
      orders: [],
      filters: {
        dueDateEnd: this.$moment(new Date()).format("MM/DD/YYYY"),
        dueDateStart: this.$moment(new Date()).format("MM/DD/YYYY"),
      },
      totalSale: 0,
      partialSale: 0,
      totalExpense: 0,
      counts: {
        finalized: 0,
        inProgress: 0,
        pendingAssignment: 0,
        toBeDelivered: 0,
      },
      chartOptions: {
        colors: ["#FCA5A5", "#6EE7B7", "#A5B4FC"],
        credits: {
          enabled: false,
        },
        chart: {
          type: "pie",
          options3d: {
            enabled: false,
            alpha: 45,
          },
          margin: [0, 0, 0, 15],
          spacingTop: 0,
          spacingBottom: 0,
          spacingLeft: 0,
          spacingRight: 0,
        },
        title: {
          text: null,
        },
        plotOptions: {
          pie: {
            innerSize: 100,
            depth: 10,
          },
        },
        series: [
          {
            name: "Estado",
            data: [
              ["Por Asignar", 0],
              ["En Proceso", 0],
              ["Por Entregar", 0],
            ],
          },
        ],
      },
      lockScroll: false,
      lockScreen: false,
      showQuickStartDialog: false,
      subscription: null,
      headerAppSubscription: null,
    };
  },
  computed: {
    today() {
      const format =
        this.$store.state.business.dateFormat?.toUpperCase() ?? "MM/DD/YYYY";
      return this.$moment(new Date()).format(format);
    },
    fullname() {
      return this.$store.state.account.fullname;
    },
    disabled() {
      return this.$store.state.account.membershipLimitReached;
    },
    restrictedStatuses() {
      const { PendingAssignment, InProgress, ToBeDelivered, Finalized } =
        getRestrictedStatuses(this.$store.state.catalogs.order.statuses);

      return {
        PendingAssignment,
        InProgress,
        ToBeDelivered,
        Finalized,
      };
    },
    utility() {
      return this.totalSale - this.totalExpense;
    },
  },
  created() {
    this.subscription = OrderPaymentDialogCommand.subscribe(
      this.handleOrderPaymentDialogCommands
    );
    this.headerAppSubscription = TheHeaderAppServices.command.subscribe(
      this.handleHeaderAppCommands
    );
  },
  destroyed() {
    this.subscription.unsubscribe();
    this.headerAppSubscription.unsubscribe();
  },
  mounted() {
    const quickStartStatus = getItem(
      LOCAL_STORAGE_SAMII_KEYS.QUICK_START_STATUS
    );
    if (!quickStartStatus || quickStartStatus === "done") {
      const dateFormatStatus = getItem(
        LOCAL_STORAGE_SAMII_KEYS.DATE_FORMAT_STATUS
      );
      if (!dateFormatStatus || dateFormatStatus === "undone") {
        this.showDateFormatSelector();
      }
      this.load();
    } else {
      this.showQuickStartDialog = true;
      // this.lockScroll = true;
      this.lockScreen = true;
    }
  },
  methods: {
    async showDateFormatSelector() {
      const { value: result } = await this.$swal.fire({
        html: `
          <strong>Configuración de Formato de Fechas</strong>
          <br>
          Ahora puedes configurar el formato de la fecha para tu negocio
        `,
        input: "select",
        inputOptions: DateFormats,
        showCancelButton: false,
        allowOutsideClick: false,
        showLoaderOnConfirm: true,
        preConfirm: async (dateFormat) => {
          try {
            await BusinessService.saveDateFormat({
              dateFormat: DateFormats[dateFormat],
            });
            return { success: true, value: dateFormat };
          } catch (error) {
            return {
              success: false,
              message: "No se pudo actualizar el formato de fecha",
            };
          }
        },
      });
      if (result.success) {
        this.$toastr.s(
          `Se configuró con exito: <br> Formato <strong>${
            DateFormats[result.value]
          }</strong>`
        );
        setItem(LOCAL_STORAGE_SAMII_KEYS.DATE_FORMAT_STATUS, "done");
      } else {
        this.$toastr.e(result.message);
      }
    },
    // TODO update this logic with src/utils/dates.js
    // Example on src/views/app/finances/Finances.vue
    async updateFilters() {
      const init = new Date();
      const last = new Date();
      if (this.active === 1) {
        this.filters.dueDateStart = this.$moment(init).format("MM/DD/YYYY");
        this.filters.dueDateEnd = this.$moment(last).format("MM/DD/YYYY");
      }
      if (this.active === 2) {
        const firstDayOfWeek = init.getDate() - init.getDay();
        const lastDayOfWeek = firstDayOfWeek + 6;
        this.filters.dueDateStart = this.$moment(
          new Date(init.setDate(firstDayOfWeek))
        ).format("MM/DD/YYYY");
        this.filters.dueDateEnd = this.$moment(
          new Date(last.setDate(lastDayOfWeek))
        ).format("MM/DD/YYYY");
      }
      if (this.active === 3) {
        const month = init.getMonth();
        const year = init.getFullYear();
        this.filters.dueDateStart = this.$moment(
          new Date(year, month, 1)
        ).format("MM/DD/YYYY");
        this.filters.dueDateEnd = this.$moment(
          new Date(year, month + 1, 0)
        ).format("MM/DD/YYYY");
      }
    },
    async changeActive(index) {
      if (this.disabled) return;
      this.active = index;
      await this.updateFilters();
      await this.load();
    },
    async load() {
      try {
        const dashboard = await HomeService.Dashboard({
          ...this.filters,
          businessInfoId: this.$store.state.business.id,
        });
        this.IsBusinessClosedThisDay = dashboard.isBusinessClosedThisDay;
        this.IsBusinessClosedThisWeek = dashboard.isBusinessClosedThisWeek;
        this.IsBusinessClosedThisMonth = dashboard.isBusinessClosedThisMonth;
        this.orders = dashboard.orders.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        this.partialSale = dashboard.partialSales;
        this.totalSale = dashboard.totalSales;
        this.totalExpense = dashboard.totalExpenses;
        this.counts = dashboard.totalsAmount;
        this.chartOptions.series = [
          {
            name: "Estado",
            data: [
              ["Por Asignar", this.counts.pendingAssignment],
              ["En Proceso", this.counts.inProgress],
              ["Por Entregar", this.counts.toBeDelivered],
            ],
          },
        ];

        const business = await this.$store.state.business;
        if (business.id && !business.timezone) {
          this.$swal
            .fire({
              title: "Falta Información en tu Perfil",
              icon: "info",
              text: "Ahora puedes indicar la zona horaria en la que te encuentras. Puedes modificarlo desde tu perfil.",
              confirmButtonText: '<i class="fa fa-thumbs-up"></i> OK',
            })
            .then(() => {
              this.$router.push({
                name: 'administration.business',
              });
            });
        }
      } catch (e) {
        this.$toastr.e(this.getErrorMessage(e));
      }
    },
    async updateOrderStatus({ orderId, status, paymentMethod = undefined }) {
      await OrderService.updateStatus({ id: orderId, status, paymentMethod });
      this.$toastr.s("Se actualizo con exito");
      await this.load();
    },
    updateStatus({ orderId, status }) {
      this.loading = true;
      try {
        const { Finalized } = this.restrictedStatuses;
        if (status === Finalized.id) {
          OrderPaymentDialogService.show({ orderId, status });
        } else {
          this.updateOrderStatus({ orderId, status });
        }
      } catch (e) {
        this.$toastr.e(this.getErrorMessage(e));
      } finally {
        this.loading = false;
      }
    },
    moneyFormat(value) {
      const { currencySymbol } = this.$store.state.account;
      return numbers.moneyFormat(value, currencySymbol);
    },
    handleSorting(sorting) {
      const keys = Object.keys(sorting);
      const direction = sorting[keys];
      this.orders = this.orders.sort((a, b) => {
        if (direction === "desc")
          return new Date(b.createdAt) - new Date(a.createdAt);
        return new Date(a.createdAt) - new Date(b.createdAt);
      });
    },
    start() {
      this.showQuickStartDialog = false;
      this.$store.dispatch(QUICK_START_STORE, { step: 1 });
    },
    close(command) {
      if (!this.$store.state.account.canCloseBusiness) {
        this.$toastr.e("No cuentas con el permiso para cerrar el dia");
        return;
      }

      if (!Object.values(CloseBusinessType).includes(command)) {
        console.warn("Comando no valido:", command);
        return;
      }
      switch (command) {
        case CloseBusinessType.Day:
          this.closeDay();
          break;
        case CloseBusinessType.Week:
          this.closeWeek();
          break;
        case CloseBusinessType.Month:
          this.closeMonth();
          break;
        default:
          console.warn("Commando no reconocido:", command);
      }
    },
    closeDay() {
      this.$confirm(
        `¿Seguro que deseas cerrar <b>día</b> en sucursal <br/><b>${this.$store.state.business.name}</b>?`,
        {
          type: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancelar",
          dangerouslyUseHTMLString: true,
        }
      ).then(async () => {
        this.$router.push({
          name: "close.business",
          params: { businessInfoId: this.$store.state.business.id },
        });
      });
    },
    closeWeek() {
      this.$confirm(
        `¿Seguro que deseas cerrar <b>semana</b> en sucursal <br/><b>${this.$store.state.business.name}</b>?`,
        {
          type: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancelar",
          dangerouslyUseHTMLString: true,
        }
      ).then(async () => {
        BusinessService.closure({
          accountId: this.$store.state.account.id,
          businessInfoId: this.$store.state.business.id,
          closureType: CloseBusinessType.Week,
        });
        this.$toastr.s(
          "El reporte semanal se enviara por correo en cuanto este listo"
        );
      });
    },
    closeMonth() {
      this.$confirm(
        `¿Seguro que deseas cerrar <b>mes</b> en sucursal <br/><b>${this.$store.state.business.name}</b>?`,
        {
          type: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancelar",
          dangerouslyUseHTMLString: true,
        }
      ).then(async () => {
        BusinessService.closure({
          accountId: this.$store.state.account.id,
          businessInfoId: this.$store.state.business.id,
          closureType: CloseBusinessType.Month,
        });
        this.$toastr.s(
          "El reporte mensual se enviara por correo en cuanto este listo"
        );
      });
    },
    handleOrderPaymentDialogCommands({ type, payload }) {
      switch (type) {
        case OrderPaymentDialogCommands.Select:
          this.updateOrderStatus({
            orderId: payload.orderId,
            status: payload.status,
            paymentMethod: payload.paymentMethod,
          });
          break;
        default:
      }
    },
    handleHeaderAppCommands({ type }) {
      switch (type) {
        case TheHeaderAppServices.commands.onBusinessChange:
          this.load();
          break;
        default:
      }
    },
  },
};
</script>
