import HttpProxy from '@/services/HttpProxy';

export default class HomeService {
  static async Dashboard(parameters) {
    const response = await HttpProxy.submit({
      method: 'get',
      url: 'Orders/Dashboard',
      parameters,
    });
    return response?.data ?? null;
  }
}
